import { CustomerWithReference, ReferenceModel } from 'generated-types/skills/customer_references';
import { useLocalStorage } from 'hooks/misc/useLocalStorage';
import { useCustomerReferencesHook } from 'hooks/skills/useCustomerReferences';
import { useState } from 'react';
import { Button, ButtonGroup, FlexboxGrid, Panel } from 'rsuite';

import { CustomerReferenceEditForm } from './CustomerReferenceEditForm';

export const CustomerReference = ({
    reference,
    customerWithReference,
    editAllowed,
}: {
    customerWithReference: CustomerWithReference;
    reference: ReferenceModel;
    editAllowed: boolean;
}) => {
    const [theme] = useLocalStorage<'light' | 'dark'>('theme', 'light');
    const [editMode, setEditMode] = useState(false);

    const { refetch: refetchReferences } = useCustomerReferencesHook();

    if (editMode) {
        return (
            <CustomerReferenceEditForm
                reference={reference}
                customer={customerWithReference}
                setEditMode={setEditMode}
                refetchReferences={refetchReferences}
            />
        );
    }
    return (
        <Panel
            bordered
            shaded
            key={reference.reference_uuid}
            style={{
                marginBottom: 20,
                background: 'white',
                color: theme === 'dark' ? '#0f131a' : 'inherit',
            }}
        >
            <FlexboxGrid justify="space-between">
                <FlexboxGrid.Item>
                    <h4>{customerWithReference.customer.name}</h4>
                </FlexboxGrid.Item>
                {editAllowed && (
                    <FlexboxGrid.Item>
                        <ButtonGroup>
                            <Button onClick={() => setEditMode(true)}>Edit</Button>
                        </ButtonGroup>
                    </FlexboxGrid.Item>
                )}
            </FlexboxGrid>
            <h5 style={{ marginTop: 20 }}>Challenge</h5>
            <p
                style={{
                    fontSize: 15,
                    margin: 20,
                    textAlign: 'justify',
                    whiteSpace: 'pre-wrap',
                }}
            >
                {reference.challenge}
            </p>
            <h5 style={{ marginTop: 20 }}>Solution</h5>
            <p
                style={{
                    fontSize: 15,
                    margin: 20,
                    textAlign: 'justify',
                    whiteSpace: 'pre-wrap',
                }}
            >
                {reference.solution}
            </p>
            <h5 style={{ marginTop: 20 }}>Quote</h5>
            <p
                style={{
                    fontSize: 15,
                    margin: 20,
                    textAlign: 'justify',
                    whiteSpace: 'pre-wrap',
                }}
            >
                {reference.quote}
            </p>
        </Panel>
    );
};
