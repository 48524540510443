import { z } from 'zod';

export const referenceTextfieldMaxLength = 2000;

export const NewCustomerReferenceForm = z.object({
    quote: z.string().min(1).max(referenceTextfieldMaxLength),
    solution: z.string().min(1).max(referenceTextfieldMaxLength),
    challenge: z.string().min(1).max(referenceTextfieldMaxLength),
});

export const EditCustomerReferenceForm = z.object({
    reference_uuid: z.string(),
    challenge: z.string().min(1).max(referenceTextfieldMaxLength),
    solution: z.string().min(1).max(referenceTextfieldMaxLength),
    quote: z.string().min(1).max(referenceTextfieldMaxLength),
});

export type NewCustomerReferenceFormType = z.infer<typeof NewCustomerReferenceForm>;
export type EditCustomerReferenceFormType = z.infer<typeof EditCustomerReferenceForm>;
