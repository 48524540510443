import { zodResolver } from '@hookform/resolvers/zod';
import { isExternalRouter } from 'auth/cognito';
import { AxiosError } from 'axios';
import { FieldInputLength } from 'components/FieldInputLength';
import { CloseFn } from 'components/misc/ExpandableForm';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import { useCompaniesHook, UseCompaniesType } from 'hooks/skills/useCompanies';
import { useCustomerReferencesHook } from 'hooks/skills/useCustomerReferences';
import { useMyExternalProfile, useProfile } from 'hooks/useProfile';
import { useState } from 'react';
import { Controller, FieldErrors, SubmitHandler, useForm } from 'react-hook-form';
import { Button, Col, Input, InputPicker, Row } from 'rsuite';
import {
    NewCustomerReferenceForm,
    NewCustomerReferenceFormType,
    referenceTextfieldMaxLength,
} from 'schema/customer_references/reference';

import { ErrorParagraph } from '../log-hours/styles';

export const CustomerReferenceAddForm = ({
    onAdded,
    refetchReferences,
}: {
    onAdded: CloseFn;
    refetchReferences: () => void;
}) => {
    const hookToUseForProfile = isExternalRouter ? useMyExternalProfile : useProfile;
    const { user } = hookToUseForProfile();
    const useCompanies = useCompaniesHook(isExternalRouter);

    const [selectedCompany, setSelectedCompany] = useState<UseCompaniesType>();

    const { companies, refetch: refetchCompanies } = useCompanies({
        enabled: true,
        member_id: user?.id,
    });

    const circlesCustomers =
        companies.filter(company => company.circles_customer_id !== null) || [];

    const { createReference } = useCustomerReferencesHook();

    const {
        formState: { errors },
        watch,
        control,
        handleSubmit,
    } = useForm<NewCustomerReferenceFormType>({
        resolver: zodResolver(NewCustomerReferenceForm),
    });

    const onSubmit: SubmitHandler<NewCustomerReferenceFormType> = data => {
        const customer_id = selectedCompany?.circles_customer_id;

        if (customer_id === undefined) {
            return;
        }

        createReference.mutate(data, customer_id, {
            onSuccess: async () => {
                await Promise.all([refetchCompanies(), refetchReferences()]);
                showNotification({ header: 'New reference created successfully.' });
                onAdded();
            },
            onError: (error: AxiosError) => {
                showErrorNotification(error);
            },
        });
    };

    const onError = (errors: FieldErrors<NewCustomerReferenceFormType>) => {
        console.error('Form Errors:', errors);
    };

    const watchFields = watch();

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)}>
            <Row>
                <Col xs={18}>
                    <p>Company</p>
                    <InputPicker
                        value={selectedCompany ? selectedCompany.value : null}
                        onSelect={(value, item) => {
                            setSelectedCompany({
                                label: String(item.label),
                                value: Number(value),
                                group: item.group,
                                created: item.created,
                                circles_customer_id: item.circles_customer_id,
                            });
                        }}
                        onClean={() => setSelectedCompany(undefined)}
                        placeholder="Select"
                        groupBy="group"
                        data={circlesCustomers}
                        block
                        size="lg"
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <p>
                        Challenge<span style={{ color: 'red' }}>*</span>
                    </p>
                    <Controller
                        name="challenge"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <>
                                <Input size="lg" as="textarea" rows={5} {...field} />
                                <FieldInputLength
                                    currentLength={watchFields.challenge?.length}
                                    maxLength={referenceTextfieldMaxLength}
                                />
                            </>
                        )}
                    />
                    <ErrorParagraph>{errors?.challenge?.message}</ErrorParagraph>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <p>
                        Solution<span style={{ color: 'red' }}>*</span>
                    </p>
                    <Controller
                        name="solution"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <>
                                <Input size="lg" as="textarea" rows={5} {...field} />
                                <FieldInputLength
                                    currentLength={watchFields.solution?.length}
                                    maxLength={referenceTextfieldMaxLength}
                                />
                            </>
                        )}
                    />
                    <ErrorParagraph>{errors?.challenge?.message}</ErrorParagraph>
                </Col>
            </Row>
            <Row>
                <Col xs={24}>
                    <p>
                        Quote<span style={{ color: 'red' }}>*</span>
                    </p>
                    <Controller
                        name="quote"
                        defaultValue=""
                        control={control}
                        render={({ field }) => (
                            <>
                                <Input size="lg" as="textarea" rows={5} {...field} />
                                <FieldInputLength
                                    currentLength={watchFields.solution?.length}
                                    maxLength={referenceTextfieldMaxLength}
                                />
                            </>
                        )}
                    />
                    <ErrorParagraph>{errors?.challenge?.message}</ErrorParagraph>
                </Col>
            </Row>
            <Row>
                <Col xs={24} style={{ padding: 15 }}>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            type="submit"
                            appearance="primary"
                            color="green"
                            style={{ width: 300 }}
                            loading={createReference.isLoading}
                        >
                            Add reference
                        </Button>
                    </div>
                </Col>
            </Row>
        </form>
    );
};
