import { ExpandableForm } from 'components/misc/ExpandableForm';
import { CustomerWithReference } from 'generated-types/skills/customer_references';
import { useCustomerReferencesHook } from 'hooks/skills/useCustomerReferences';
import { Loader, Panel } from 'rsuite';

import { CustomerReference } from './skills/CustomerReference';
import { CustomerReferenceAddForm } from './skills/CustomerReferenceAddForm';

const CustomerReferencesScreen = () => {
    const {
        customerReferences,
        isLoading,
        refetch: refetchReferences,
    } = useCustomerReferencesHook();

    const editAllowed = true;

    return (
        <div>
            <ExpandableForm
                formTitle="Add Customer Reference"
                style={{ maxWidth: 800, width: '100%' }}
                render={done => (
                    <CustomerReferenceAddForm
                        onAdded={done}
                        refetchReferences={refetchReferences}
                    />
                )}
            />

            <Panel
                bordered
                style={{ marginBottom: 20, marginTop: 20 }}
                header={<h3>Customer References</h3>}
            >
                {isLoading ? (
                    <Loader content={'Customer References are loading...'} />
                ) : customerReferences.length === 0 ? (
                    <p>No references added yet</p>
                ) : (
                    <>
                        {customerReferences.length > 0 && (
                            <Panel
                                bordered
                                style={{
                                    marginBottom: 20,
                                    paddingTop: 20,
                                    background:
                                        'linear-gradient(145deg, rgba(31,59,112,1) 0%, rgba(2,175,217,1) 50%, rgba(217,22,92,1) 100%)',
                                }}
                            >
                                {(customerReferences as CustomerWithReference[]).map(
                                    (customerReference: CustomerWithReference) => (
                                        <CustomerReference
                                            key={`${customerReference.customer.circles_customer_id}-${customerReference.reference?.reference_uuid}`}
                                            editAllowed={editAllowed}
                                            customerWithReference={customerReference}
                                            reference={customerReference.reference}
                                        />
                                    ),
                                )}
                            </Panel>
                        )}
                    </>
                )}
            </Panel>
        </div>
    );
};

export default CustomerReferencesScreen;
