import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import ConfirmationDialog from 'components/dialogs/ConfirmationDialog';
import { FieldInputLength } from 'components/FieldInputLength';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import { CustomerWithReference, ReferenceModel } from 'generated-types/skills/customer_references';
import { useLocalStorage } from 'hooks/misc/useLocalStorage';
import { useCustomerReferencesHook } from 'hooks/skills/useCustomerReferences';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Button, ButtonGroup, FlexboxGrid, Input, Panel } from 'rsuite';
import {
    EditCustomerReferenceForm,
    EditCustomerReferenceFormType,
    referenceTextfieldMaxLength,
} from 'schema/customer_references/reference';

export const CustomerReferenceEditForm = ({
    reference,
    customer,
    setEditMode,
    refetchReferences,
}: {
    reference: ReferenceModel;
    customer: CustomerWithReference;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
    refetchReferences: () => void;
}) => {
    const { updateReference, deleteReference } = useCustomerReferencesHook();

    const { watch, control, handleSubmit, register } = useForm<EditCustomerReferenceFormType>({
        resolver: zodResolver(EditCustomerReferenceForm),
    });

    const [theme] = useLocalStorage<'light' | 'dark'>('theme', 'light');

    const onClose = () => {
        setEditMode(false);
    };

    const handleDelete = (reference_uuid: string) => {
        deleteReference.mutate(reference_uuid, {
            onSuccess: () => {
                showNotification({ header: 'Reference deleted successfully.' });
                refetchReferences();
                setEditMode(false);
            },
            onError: (error: AxiosError) => {
                showErrorNotification(error);
            },
        });
    };

    const onSave: SubmitHandler<EditCustomerReferenceFormType> = data => {
        updateReference.mutate(data, {
            onSuccess: () => {
                setEditMode(false);
                showNotification({ header: 'Reference edited successfully.' });
                refetchReferences();
            },
            onError: (error: AxiosError) => {
                showErrorNotification(error);
            },
        });
    };

    const watchFields = watch();

    return (
        <form onSubmit={handleSubmit(onSave)}>
            <input hidden {...register('reference_uuid')} defaultValue={reference.reference_uuid} />
            <Panel
                bordered
                shaded
                key={reference.reference_uuid}
                style={{
                    marginBottom: 20,
                    background: 'white',
                    color: theme === 'dark' ? '#0f131a' : 'inherit',
                }}
            >
                <h5>{customer.customer.name}</h5>
                <FlexboxGrid justify="end">
                    <FlexboxGrid.Item>
                        <ButtonGroup>
                            <Button
                                appearance="primary"
                                color="green"
                                type="submit"
                                loading={updateReference.isLoading}
                            >
                                Save
                            </Button>
                            <Button onClick={() => onClose()}>Close</Button>
                        </ButtonGroup>
                    </FlexboxGrid.Item>

                    <FlexboxGrid.Item colspan={24}>
                        <p>
                            <strong>Challenge</strong>
                            <span style={{ color: 'red' }}>*</span>
                        </p>
                        <Controller
                            name="challenge"
                            control={control}
                            defaultValue={reference.challenge}
                            render={({ field }) => (
                                <>
                                    <Input size="lg" as="textarea" rows={6} {...field} />
                                    <FieldInputLength
                                        currentLength={watchFields.challenge?.length}
                                        maxLength={referenceTextfieldMaxLength}
                                    />
                                </>
                            )}
                        />
                        <p>
                            <strong>Solution</strong>
                            <span style={{ color: 'red' }}>*</span>
                        </p>
                        <Controller
                            name="solution"
                            control={control}
                            defaultValue={reference.solution}
                            render={({ field }) => (
                                <>
                                    <Input size="lg" as="textarea" rows={6} {...field} />
                                    <FieldInputLength
                                        currentLength={watchFields.solution?.length}
                                        maxLength={referenceTextfieldMaxLength}
                                    />
                                </>
                            )}
                        />
                        <p>
                            <strong>Quote</strong>
                            <span style={{ color: 'red' }}>*</span>
                        </p>
                        <Controller
                            name="quote"
                            control={control}
                            defaultValue={reference.quote}
                            render={({ field }) => (
                                <>
                                    <Input size="lg" as="textarea" rows={6} {...field} />
                                    <FieldInputLength
                                        currentLength={watchFields.quote?.length}
                                        maxLength={referenceTextfieldMaxLength}
                                    />
                                </>
                            )}
                        />
                        <FlexboxGrid justify="end">
                            <FlexboxGrid.Item>
                                <ConfirmationDialog
                                    handleOK={() => handleDelete(reference.reference_uuid)}
                                    header={'Delete Customer Reference'}
                                    message={
                                        'Are you sure you want to delete this customer reference?'
                                    }
                                >
                                    <Button
                                        appearance="primary"
                                        color="red"
                                        loading={deleteReference.isLoading}
                                    >
                                        Delete
                                    </Button>
                                </ConfirmationDialog>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </Panel>
        </form>
    );
};
