import GlobalIcon from '@rsuite/icons/Global';
import LegacyCircleThinIcon from '@rsuite/icons/legacy/CircleThin';
import LegacyEmailIcon from '@rsuite/icons/legacy/Envelope';
import LegacyEurIcon from '@rsuite/icons/legacy/Eur';
import GearIcon from '@rsuite/icons/legacy/Gear';
import LegacyGroupIcon from '@rsuite/icons/legacy/Group';
import LegacyHomeIcon from '@rsuite/icons/legacy/Home';
import LegacyIndustryIcon from '@rsuite/icons/legacy/Industry';
import LemonIcon from '@rsuite/icons/legacy/LemonO';
import LegacyListIcon from '@rsuite/icons/legacy/List';
import LegacyMoneyIcon from '@rsuite/icons/legacy/Money';
import LegacyPeoplesIcon from '@rsuite/icons/legacy/Peoples';
import PieChartIcon from '@rsuite/icons/legacy/PieChart';
import LegacyProjectIcon from '@rsuite/icons/legacy/Project';
import QuoteLeftIcon from '@rsuite/icons/legacy/QuoteLeft';
import LegacyTableIcon from '@rsuite/icons/legacy/Table';
import WikiIcon from '@rsuite/icons/legacy/WikipediaW';
import PageIcon from '@rsuite/icons/Page';
import PushMessageIcon from '@rsuite/icons/PushMessage';
import { useProfile } from 'hooks/useProfile';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Nav, Sidenav } from 'rsuite';

const OPEN_WHEN_WIDER_THAN = 800;
const HAPPEO_URL = 'https://app.happeo.com/pages/1cjvvdksak0mfr4slu/FrontPage/1cjvvdl6lgfglc6j6p';

const SideNavigation = () => {
    const navigate = useNavigate();
    const { isAdmin, user } = useProfile();

    const [activeKey, setActiveKey] = useState('1');
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const expanded = viewPortWidth >= OPEN_WHEN_WIDER_THAN;

    const handleSelect = (eventKey: string) => {
        navigate(eventKey);
        setActiveKey(eventKey);
    };

    const updateWindowDimensions = () => {
        setViewPortWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions);
        return function cleanup() {
            window.removeEventListener('resize', updateWindowDimensions);
        };
    }, []);

    return (
        <Sidenav style={{ height: '100%' }} expanded={expanded}>
            <Sidenav.Body>
                <Nav activeKey={activeKey} onSelect={handleSelect}>
                    <Nav.Item eventKey="/" icon={<LegacyHomeIcon />}>
                        Home
                    </Nav.Item>
                    <Nav.Item eventKey="/my/hours" icon={<PieChartIcon />}>
                        Log Hours
                    </Nav.Item>
                    <Nav.Item eventKey={`/${user?.id}/career`} icon={<LemonIcon />}>
                        My Career
                    </Nav.Item>
                    <Nav.Item eventKey="/resume" icon={<PageIcon />}>
                        My Resume
                    </Nav.Item>
                    <Nav.Item eventKey="/my/timetracks" icon={<LegacyTableIcon />}>
                        My Time Tracking
                    </Nav.Item>
                    <Nav.Item eventKey="/my/projects" icon={<LegacyProjectIcon />}>
                        My Projects
                    </Nav.Item>
                    <Nav.Item eventKey="/company" icon={<GlobalIcon />}>
                        Company
                    </Nav.Item>
                    <Nav.Item eventKey="/ledger" icon={<LegacyEurIcon />}>
                        Ledger
                    </Nav.Item>
                    <Nav.Item eventKey="/circles" icon={<LegacyCircleThinIcon />}>
                        Circles
                    </Nav.Item>
                    <Nav.Item eventKey="/members" icon={<LegacyGroupIcon />}>
                        Members
                    </Nav.Item>
                    <Nav.Item eventKey="/memberships" icon={<LegacyPeoplesIcon />}>
                        Memberships
                    </Nav.Item>
                    <Nav.Item eventKey="/weights" icon={<LegacyMoneyIcon />}>
                        Weight Simulator
                    </Nav.Item>
                    <Nav.Item eventKey="/revenue" icon={<LegacyEurIcon />}>
                        Revenue
                    </Nav.Item>
                    <Nav.Item target="_blank" href={HAPPEO_URL} icon={<PushMessageIcon />}>
                        Happeo
                    </Nav.Item>
                    <Nav.Item eventKey="/wiki/1" icon={<WikiIcon />}>
                        Wiki
                    </Nav.Item>

                    {isAdmin && (
                        <>
                            <Divider>Admin</Divider>
                            <Nav.Item eventKey="/customers" icon={<LegacyIndustryIcon />}>
                                Customers
                            </Nav.Item>
                            <Nav.Item eventKey="/control-panel/holidays" icon={<GearIcon />}>
                                Control Panel
                            </Nav.Item>
                            <Nav.Item eventKey="/internalprojects" icon={<LegacyProjectIcon />}>
                                Internal Projects
                            </Nav.Item>
                            <Nav.Item eventKey="/customerprojects" icon={<LegacyProjectIcon />}>
                                Customer Projects
                            </Nav.Item>
                            <Nav.Item eventKey="/customerreferences" icon={<QuoteLeftIcon />}>
                                Customer References
                            </Nav.Item>
                            <Nav.Item eventKey="/internalprojecttypes" icon={<LegacyListIcon />}>
                                Project Types
                            </Nav.Item>
                            <Nav.Item eventKey="/timetracks" icon={<LegacyTableIcon />}>
                                Timetracks
                            </Nav.Item>
                            <Nav.Item eventKey="/externalUsers/" icon={<LegacyEmailIcon />}>
                                External Users
                            </Nav.Item>
                        </>
                    )}
                </Nav>
            </Sidenav.Body>
        </Sidenav>
    );
};

export default SideNavigation;
