import lambdaApi from 'api/LambdaApi';
import { AxiosError } from 'axios';
import { showErrorNotification, showNotification } from 'components/ShowNotification';
import {
    CustomerWithReference,
    NewReferenceModel,
    ReferenceModel,
} from 'generated-types/skills/customer_references';
import { useMutation, useQuery } from 'react-query';

type MutationOptions = {
    onSuccess?: () => void;
    onError?: (error: AxiosError) => void;
};

interface UseCustomerReferencesReturn {
    customerReferences: CustomerWithReference[];
    updateReference: {
        mutate: (
            updatedData: ReferenceModel,
            options?: {
                onSuccess?: () => void;
                onError?: (error: AxiosError) => void;
            },
        ) => void;
        isLoading: boolean;
    };
    deleteReference: {
        mutate: (
            uuid: string,
            options?: {
                onSuccess?: () => void;
                onError?: (error: AxiosError) => void;
            },
        ) => void;
        isLoading: boolean;
    };
    createReference: {
        mutate: (
            newReferenceData: NewReferenceModel,
            customer_id: number,
            options?: {
                onSuccess?: () => void;
                onError?: (error: AxiosError) => void;
            },
        ) => void;
        isLoading: boolean;
    };
    isLoading: boolean;
    refetch: () => void;
    isError: boolean;
}

const useCustomerReferencesHook = (): UseCustomerReferencesReturn => {
    const referencesUri = '/skills/customer-references';

    const { data, isLoading, refetch, isError } = useQuery<CustomerWithReference[], AxiosError>(
        ['customer_references'],
        () => lambdaApi.get<CustomerWithReference[]>(referencesUri).then(res => res.data),
        {
            retry: false,
            enabled: true,
        },
    );

    const updateReferenceMutation = useMutation<unknown, AxiosError, ReferenceModel>(
        async (updatedData: ReferenceModel) => {
            const updateUri = `${referencesUri}/${updatedData.reference_uuid}`;
            const res = await lambdaApi.put(updateUri, updatedData);
            return res.data;
        },
    );

    const deleteReferenceMutation = useMutation<void, AxiosError, string>(
        async (reference_uuid: string) => {
            const deleteUri = `${referencesUri}/${reference_uuid}`;
            const res = await lambdaApi.delete(deleteUri);
            return res.data;
        },
    );

    const createReferenceMutation = useMutation<
        unknown,
        AxiosError,
        { customer_id: string; data: NewReferenceModel }
    >(async ({ customer_id, data }) => {
        const dataWithCustomerId = { ...data, customer_id };

        const uriWithCustomerId = `${referencesUri}`;
        const res = await lambdaApi.post(uriWithCustomerId, dataWithCustomerId);
        return res.data;
    });

    return {
        customerReferences: data ?? [],
        updateReference: {
            mutate: (
                updatedData: ReferenceModel,
                options?: {
                    onSuccess?: () => void;
                    onError?: (error: AxiosError) => void;
                },
            ) => {
                updateReferenceMutation.mutate(updatedData, options);
            },
            isLoading: updateReferenceMutation.isLoading,
        },
        deleteReference: {
            mutate: (
                uuid: string,
                options?: {
                    onSuccess?: () => void;
                    onError?: (error: AxiosError) => void;
                },
            ) => {
                deleteReferenceMutation.mutate(uuid, options);
            },
            isLoading: deleteReferenceMutation.isLoading,
        },
        createReference: {
            mutate: (
                newReferenceData: NewReferenceModel,
                customer_id: number,
                options?: MutationOptions,
            ) =>
                createReferenceMutation.mutate(
                    {
                        data: newReferenceData,
                        customer_id: customer_id.toString(),
                    },
                    options,
                ),
            isLoading: createReferenceMutation.isLoading,
        },
        isLoading,
        refetch,
        isError,
    };
};

export { useCustomerReferencesHook };
